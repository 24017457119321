import React, { useEffect, useState } from "react";
import { TextField, Typography, Grid, Dialog, Container } from "@material-ui/core";
import { CommonStyles } from "../../commons/CommonStyles";
import { CorreosButton } from "../../components/CorreosButton/CorreosButton";
import history from "../../services/history";
import {
  PostVerificationSendEmail,
  // PostEnterprise,
} from "../../services/ReasonService";
import {
  PostApplicationEmail,
} from "../../services/ApplicationCallsService";
import { checkIsNullUndefined, getErrorMessage } from "../../commons/Utils";
import { useTranslation } from "react-i18next";
import { DialogStyles } from "../CommonComponents/Dialogs/DialogStyles";
import { backToLogin, checkForInvitations, doLogin, setSessionItem } from "../../services/Commons";
import { BasicDialog } from "../CommonComponents/BasicDialog";
import { generalWarning, sessionValues } from "../../commons/EnumsGeneral";
import { GeneralWarning } from "../CommonComponents/GeneralWarning/GeneralWarning";

export function IdentityVerify(props) {
  const commonClasses = CommonStyles();
  const dialogClasses = DialogStyles();
  const { t, i18n } = useTranslation();
  const {
    data,
    isPF,
    changeStep,
    // enterpriseMandatoryData,
    setInvitationPath,
    open,
    finishOperation,
  } = props;

  const [email, setEmail] = useState("");
  const [emailSended, setEmailSended] = useState(false);
  const [emailIsSending, setEmailIsSending] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [circularProgress, setCircularProgress] = useState(false);
  const [openWarnDialog, setOpenWarnDialog] = useState(false);
  const [openGeneralWarnDialog, setOpenGeneralWarnDialog] = useState(false);


  useEffect(() => {
    if (!checkIsNullUndefined(data) && !checkIsNullUndefined(data.userEmail)) {
      setEmail(data.userEmail.email);
    };
  }, [data]);

  const handleCancel = () => {
    setEmail("");
    setEmailSended(false);
    setEmailIsSending(false);
    setOtp("");
    setOtpError(false);
    changeStep(0)
  };

  const handleSendOTP = () => {
    setEmailIsSending(true);
    PostVerificationSendEmail(email).then((response) => {
      if (response && !response.Status) {
        setEmailSended(true);
      } else {
        //Alerta
        finishOperation("error", getErrorMessage(response, t));
      };
      setEmailIsSending(false);
    });
  };

  const handleRegistry = () => {
    setCircularProgress(true);
    let typePerson = isPF ? 1 : 2;
    let auxiliarData = {
      password: data.password,
      password2: data.password2,
      userRgpd: data.userRgpd,
    };
    auxiliarData.userEmail = data.userEmail;
    auxiliarData.userEmail.otp = otp.trim();
    PostApplicationEmail(auxiliarData, typePerson, process?.env?.REACT_APP_APP_OID).then((response) => {
      if (response && !response.Status) {
        manageLogin();
      } else {
        setOpenGeneralWarnDialog(true);
        setCircularProgress(false);
      };
    });
  };

  const manageLogin = () => {
    setSessionItem("isPF", isPF);
    let hasInvitation = checkForInvitations();
    setSessionItem(sessionValues.replaceMode, !hasInvitation);
    let user = data.userEmail.email;
    doLogin(user, data.password, i18n, hasInvitation).then(() => {
      if (hasInvitation) {
        setInvitationPath(true);
      } else {
        history.push("/Registry/SubscriptionData");
        // checkForEnterprise();
      };
    });
  };

  // const checkForEnterprise = () => {
  //   if (isPF) {
  //     handleRedirection();
  //   } else {
  //     PostEnterprise(enterpriseMandatoryData.enterpriseMandatoryData).then((response) => {
  //       if (response && !response.Status) {
  //         sessionStorage.setItem("enterpriseId", response);
  //         handleRedirection();
  //       } else {
  //         setOpenWarnDialog(true);
  //         changeStep(0);
  //       }
  //     });
  //   };
  // };

  // const handleRedirection = () => {
  //   history.push("/Registry/SubscriptionData");
  // };

  const handleChangeOtp = (value) => {
    const regex = /^[0-9\b]{0,12}$/;
    let auxError = false;
    if (regex.test(value)) {
      if (checkIsNullUndefined(value)) {
        auxError = true;
      };
      setOtp(value);
      setOtpError(auxError);
    };
  };

  return (
    <>
      <BasicDialog
        open={openWarnDialog}
        setOpen={setOpenWarnDialog}
        title={t("registerPageWarnTitle")}
        text={t("registerPageWarnText")}
        action={() => { backToLogin() }}
        closeAction={() => { backToLogin() }}
      />
      <GeneralWarning open={openGeneralWarnDialog} setOpen={setOpenGeneralWarnDialog} isEmail={true} warnType={generalWarning.registry} />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleCancel}
        PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
      >
        <Container className={commonClasses.dialogTitle}>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="h4" className={commonClasses.dialogTitleText}>
                {t("registerPageIdentityVerifyTitle")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container className={commonClasses.dialogContainer}>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} >
              <Typography variant="h6" className={commonClasses.dialogTitleText} >
                {t("registerPageIdentityVerifyText")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                variant="filled"
                color="secondary"
                value={email}
                fullWidth
                name="Email"
                disabled={true}
                label={t("UserEmail")}
                id="Email"
                className={commonClasses.inputsStyle}
              />
            </Grid>
            <Grid item xs={12} sm={3} style={{ margin: "auto" }}>
              <CorreosButton
                variant={emailSended ? "text" : "contained"}
                color={emailSended ? "secondary" : "primary"}
                onClick={() => handleSendOTP()}
                circularProgress={emailIsSending}
                fullWidth
                disabled={circularProgress}
              >
                {emailSended
                  ? t("reSend")
                  : t("send")}
              </CorreosButton>
            </Grid>
            {emailSended ? (
              <Grid item xs={12}>
                <TextField
                  color="secondary"
                  value={otp}
                  onChange={(e) => handleChangeOtp(e.target.value)}
                  variant="filled"
                  fullWidth
                  name="Otp"
                  label={t("registerPageSendOTPEmail")}
                  id="Otp"
                  error={otpError}
                  helperText={otpError ? t("required") : ""}
                  className={commonClasses.inputsStyle}
                />
              </Grid>
            ) : null}
            <Grid
              item
              container
              xs={12}
              className={commonClasses.dialogButtonContainer}
            >
              <Grid item xs={6}>
                <CorreosButton
                  onClick={handleCancel}
                  className={commonClasses.dialogButtonLeft}
                >
                  {t("cancel")}
                </CorreosButton>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  variant="contained"
                  color="primary"
                  onClick={() => handleRegistry()}
                  className={commonClasses.dialogButtonRight}
                  disabled={!emailSended || checkIsNullUndefined(otp) || emailIsSending}
                  circularProgress={circularProgress}
                >
                  {t("next")}
                </CorreosButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </>
  );
}
