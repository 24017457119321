import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import {
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from "@material-ui/core";
import {
  PostAuthorizeAuthorize,
  PostAuthorizeToken,
  GetProfileUser,
} from "../../services/ReasonService";
import {
  GetUtilitiesCorreosIdGetUrlRedirectOauth,
  GetUtilitiesCorreosIdUserExpiredPass,
  PostUtilitiesCorreosIdVerifyRecaptcha,
} from "../../services/UtilitiesCallsService";
import {
  setUserCookies,
  setUserSession,
  getApplicationOid,
  getEntranceUrl,
  checkUserPath,
  setSessionCookie,
  getSessionItem,
  setSessionItem,
  controlUserMovement,
  goToLoginOauth,
} from "../../services/Commons";
import ico_pass_on from "../../assets/images/ico_pass_on.svg";
import ico_pass_off from "../../assets/images/ico_pass_off.svg";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { CommonStyles } from "../../commons/CommonStyles";
import { LogInStyles } from "./LogInStyles";
import { EnumPages } from "../../commons/EnumPages";
import { checkIsNullUndefined, getErrorMessage, getScreenWidth } from "../../commons/Utils";
import { PrefixPhone } from "../PrefixPhone/PrefixPhone";
import { DialogPrivacy } from "../CommonComponents/Dialogs/DialogPrivacy";
import { DialogSubscribe } from "../CommonComponents/Dialogs/DialogSubscribe";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { SelectorSwitch } from "../CommonComponents/SelectorSwitch";
import { validatePhone, validatePhoneByPrefix } from "../../commons/Utils";
import { Size, sessionValues } from "../../commons/EnumsGeneral";
import { BasicDialog } from "../CommonComponents/BasicDialog";
import history from "../../services/history";
import { Dialog2FASetPhoneLogin } from "./Dialog2FASetPhoneLogin";

export const LogIn = (props) => {
  const { setEnterprisePath, setAuthorizationUrl, setCode, setUserValue, personTypeSelected, SSOResp, cookieInfo, finishOperation, masterCountryList } = props;
  const commonClasses = CommonStyles();
  const classes = LogInStyles();
  const cookies = new Cookies();
  const { t, i18n } = useTranslation();
  const validator = require("react-email-validator");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [prefixPhone, setPrefixPhone] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);
  const [errorMsgLogin, setErrorMsgLogin] = useState("");
  const [errorMsgUser, setErrorMsgUser] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPhoneSelector, setShowPhoneSelector] = useState(false);
  const [disableLoginButton, setDisableLoginButton] = useState(true);
  const [progressBar, setProgressBar] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const [openSubscribe, setOpenSubscribe] = useState(false);
  const [showReCaptcha, setShowReCaptcha] = useState(true);
  const [reCaptchaToken, setReCaptchaToken] = useState(null);
  const [reCaptchaError, setReCaptchaError] = useState("");
  const [reCaptchaRef, setReCaptchaRef] = useState(null);
  const [openResetPass, setOpenResetPass] = useState(false);
  const [open2FA, setOpen2FA] = useState(false);
  const [auxiliarInfo, setAuxiliarInfo] = useState({});
  const [openACM, setOpenACM] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("method") === "Oauth") {
      finishOperation("error", t("loginOauthError"));
    };
  }, []);

  useEffect(() => {
    if (errorLogin) {
      setErrorLogin(false);
      setErrorMsgLogin("");
    };
    let auxDisableLoginButton = getDisblaLoginButton();
    setDisableLoginButton(auxDisableLoginButton);
  }, [username, password, reCaptchaToken, prefixPhone]);

  useEffect(() => {
    if (!checkIsNullUndefined(SSOResp)) {
      setProgressBar(true);
      manageUserPath(SSOResp, null);
    };
  }, [SSOResp]);

  useEffect(() => {
    if (!checkIsNullUndefined(cookieInfo)) {
      setUsername(cookieInfo.username);
      setRememberMe(cookieInfo.rememberMe);
      setShowPhoneSelector(cookieInfo.showPhoneSelector);
    };
  }, [cookieInfo]);

  useEffect(() => {
    if (!open2FA) {
      setProgressBar(false);
    };
  }, [open2FA]);

  const getDisblaLoginButton = () => {
    let auxDisableLoginButton = true;
    let auxFlag = !checkIsNullUndefined(username) &&
      !checkIsNullUndefined(password) &&
      (process?.env?.REACT_APP_ENVELOP_ACTIVE_CAPTCHA == "false" || !checkIsNullUndefined(reCaptchaToken));
    if (auxFlag && ((showPhoneSelector && validatePhoneByPrefix(username, prefixPhone)) || (!showPhoneSelector && checkIsValidEmail(username)))) {
      auxDisableLoginButton = false;
    };
    return auxDisableLoginButton;
  };

  const handleBlur = (event) => {
    const value = event.target.value;
    if (showPhoneSelector) {
      if (value === "" || validatePhoneByPrefix(value, prefixPhone)) {
        setErrorMsgUser("");
      } else {
        setErrorMsgUser(t("loginFormatError"));
      };
    } else {
      if (value === "" || checkIsValidEmail(value)) {
        setErrorMsgUser("");
      } else {
        setErrorMsgUser(t("loginFormatError"));
      };
    };
  };

  const checkIsValidEmail = (value) => {
    return validator.validate(value);
  };

  //inicializa los estados para que vuelva a aparecer
  //el input de indetidad de correos y desaparezca el avatar.
  const inicializeInputStates = () => {
    if (process?.env?.REACT_APP_ENVELOP_ACTIVE_CAPTCHA != "false") {
      reCaptchaRef?.reset();
    };
    setReCaptchaToken(null);
    setShowReCaptcha(true);
    setDisableLoginButton(true);
    setProgressBar(false);
  };

  const generateCookie = (user) => {
    //usuario de isam
    if (
      (user.startsWith("E") || user.startsWith("C") || user.startsWith("P")) &&
      !user.includes("@") &&
      user.length == 7
    ) {
      //guardamos los datos que recibimos en un json para guardarlo en la cookie de inicio de sesión.
      let userCorreosIdSignIn = {
        username: username,
        showPhoneSelector: showPhoneSelector,
        // selectorValue: selectorValue,
        prefixPhone: prefixPhone,
        rememberMe: rememberMe,
        userCompleteName: username,
      };
      //ponemos el tiempo de expiración
      let expiration = new Date();
      expiration.setDate(expiration.getDate() + 365);
      //creamos la cookie con una fecha de expiracion de 1 año
      cookies.set("userCorreosIdSignIn", userCorreosIdSignIn, {
        path: "/",
        expires: expiration,
      });
    }
    //usuario de correosId
    else {
      //obtenemos el nombre y apellidos del usuario
      GetProfileUser().then((response) => {
        if (response && !response.Status) {
          //guardamos los datos que recibimos en un json para guardarlo en la cookie de inicio de sesión.
          let userCorreosIdSignIn = {
            username: username,
            showPhoneSelector: showPhoneSelector,
            // selectorValue: selectorValue,
            prefixPhone: prefixPhone,
            rememberMe: rememberMe,
            userCompleteName:
              response.name +
              " " +
              response.surname1 +
              (checkIsNullUndefined(response.surname2)
                ? ""
                : " " + response.surname2),
          };
          //ponemos el tiempo de expiración
          let expiration = new Date();
          expiration.setDate(expiration.getDate() + 365);
          //creamos la cookie con una fecha de expiracion de 1 año
          cookies.set("userCorreosIdSignIn", userCorreosIdSignIn, {
            path: "/",
            expires: expiration,
          });
        };
      });
    };
  };

  //borra la cookie de userCorreosIdSignIn del navegador
  const deleteCookie = () => {
    cookies.remove("userCorreosIdSignIn");
  };

  const getUrlPreLogin = () => {
    setProgressBar(true);
    let auxAppOid = getApplicationOid();
    GetUtilitiesCorreosIdGetUrlRedirectOauth(auxAppOid).then((response) => {
      if (response && !response.Status) {
        logIn(response[0]);
      } else {
        setProgressBar(false);
      };
    });
  };

  //inicia la sesión del usuario si todo va bien o activa los errores
  const logIn = (redirectUrl) => {
    setProgressBar(true);
    let user = username;
    if (showPhoneSelector) {
      user = prefixPhone + "-" + user;
    };
    setUserValue(user);
    let isForEnteprise = personTypeSelected == 2;
    const encodedRedirectUrl = encodeURIComponent(redirectUrl);
    PostAuthorizeAuthorize(user, password, encodedRedirectUrl, null, isForEnteprise).then((response) => {
      if (response && (!response.Status || response.Status == 212)) {
        let auxPendData = getSessionItem(sessionValues.pendingData);
        // const queryString = window.location.search;
        // const urlParams = new URLSearchParams(queryString);
        // let secondFA = urlParams.get("2FA");
        let secondFA = response.Status == 212;
        if (secondFA) {
          setAuxiliarInfo({ respo: response.response, redUrl: encodedRedirectUrl, user: user, pass: password, manageGeneralPath: manageGeneralPath });
          setOpen2FA(true);
        } else if ((getSessionItem(sessionValues.isNoToken) == "true") && !isForEnteprise && auxPendData != "true") {
          let redirectURI = response;
          window.location.href = redirectURI;
        } else {
          manageGeneralPath(response, encodedRedirectUrl, user);
        };
      } else {
        setErrorLogin(true);
        setProgressBar(false);
        setErrorMsgLogin(getErrorMessage(response, t));
      };
    });
  };

  const manageGeneralPath = (response, redirectUrl, user) => {
    let searchParams = new URLSearchParams(response);
    let code = searchParams.get('code');
    setCode(code);
    setAuthorizationUrl(redirectUrl);
    let postAuthorizeToken = PostAuthorizeToken(code, redirectUrl, null, personTypeSelected);
    Promise.all([postAuthorizeToken])
      .then((results) => {
        let postAuthorizeTokenResult = results[0];
        if (postAuthorizeTokenResult && !checkIsNullUndefined(postAuthorizeTokenResult.idToken)) {
          if (process.env.REACT_APP_ENVELOP_ACTIVE_PASS_EXP == "true") {
            checkExpiredPass(postAuthorizeTokenResult, user);
          } else {
            manageUserSession(postAuthorizeTokenResult, user);
          };
        } else {
          setErrorLogin(true);
          setProgressBar(false);
          setErrorMsgLogin(getErrorMessage(postAuthorizeTokenResult, t));
        };
      });
  };

  const checkExpiredPass = (postAuthorizeTokenResult, user) => {
    GetUtilitiesCorreosIdUserExpiredPass(getSessionItem(sessionValues.appCode), postAuthorizeTokenResult).then((response) => {
      if (!checkIsNullUndefined(response) && !response.Status) {
        if (response) {
          setSessionItem("userId", user);
          setOpenResetPass(true);
        } else {
          manageUserSession(postAuthorizeTokenResult, user);
        };
      } else {
        setErrorLogin(true);
        setProgressBar(false);
        setErrorMsgLogin(getErrorMessage(response, t));
      };
    });
  };

  const manageUserSession = (postAuthorizeTokenResult, user) => {
    setUserSession(postAuthorizeTokenResult, i18n, null).then(() => {
      if (process.env.REACT_APP_ENVELOP_ACTIVE_SESSION_COOKIE === "true") {
        setSessionCookie(postAuthorizeTokenResult.sessionCookie);
      };
      if (personTypeSelected == 2) {
        manageEnterprisePath(postAuthorizeTokenResult);
      } else {
        manageUserPath(postAuthorizeTokenResult, user);
      };
    });
  };

  const manageEnterprisePath = (postAuthorizeTokenResult) => {
    if (!postAuthorizeTokenResult.Status) {
      setEnterprisePath(true);
    } else if (postAuthorizeTokenResult.Status == 206) {
      inicializeInputStates();
      setOpenPrivacy(true);
      setProgressBar(false);
    }
  };

  const manageUserPath = (postAuthorizeTokenResult, user) => {
    let auxAppOid = getApplicationOid();
    if (!postAuthorizeTokenResult.Status) {
      setUserCookies(user, rememberMe, generateCookie);
      let auxInside = true;
      if (auxAppOid !== process?.env?.REACT_APP_APP_OID) {
        auxInside = false;
      };
      controlUserMovement(auxInside, finishOperation, t, setOpenACM);
    } else {
      switch (postAuthorizeTokenResult.Status) {
        case 202:
        case 207:
          checkUserPath(false, finishOperation, t, setOpenACM);
          break;
        case 203:
          inicializeInputStates();
          setOpenSubscribe(true);
          setProgressBar(false);
          break;
        case 206:
          inicializeInputStates();
          setOpenPrivacy(true);
          setProgressBar(false);
          break;
        default:
          setProgressBar(false);
          break;
      };
    };
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  //controla el cambio en el selector de prefijo
  const handleSelectorChange = (prefix) => {
    setPrefixPhone(prefix.phonePrefix);
    if (username === "" || validatePhone(username, prefix)) {
      setErrorMsgUser("");
    } else {
      setErrorMsgUser(t("loginFormatError"));
    };
  };

  //realiza los pasos necesarios para iniciar sesión al darle a enter en el formulario
  const handleSubmit = () => {
    if (!disableLoginButton) {
      getUrlPreLogin();
    };
  };

  const handleIdentityInputChange = (event) => {
    setErrorMsgUser("");
    setUsername(event.target.value);
  };

  //controla el botón de recuerdame.
  const handleChangeRememberMe = () => {
    let chekRememberMe = !rememberMe;
    setRememberMe(chekRememberMe);
    if (!chekRememberMe) {
      deleteCookie();
    };
  };

  const handleOnChangeReCaptcha = (value) => {
    setReCaptchaToken(null);

    if (!checkIsNullUndefined(value)) {
      setProgressBar(true);
      setReCaptchaError("");

      PostUtilitiesCorreosIdVerifyRecaptcha(value).then((response) => {
        if (response && !response.Status) {
          setReCaptchaToken(value);
        } else {
          reCaptchaRef.reset();
          setReCaptchaError(getErrorMessage(response, t));
        };
        setProgressBar(false);
      });
    };
  };

  const handleSwitchLogin = (emailSelected) => {
    setShowPhoneSelector(!emailSelected);
    setErrorMsgUser("");
    setUsername("");
  };

  const goToResetPass = (flag) => {
      if (flag) {
        history.push(getEntranceUrl(EnumPages.ResetPassword));
      } else {
        setProgressBar(false);
      };
  };

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      handleSubmit();
    }}>
      <DialogPrivacy
        open={openPrivacy}
        setOpen={setOpenPrivacy}
        finishOperation={finishOperation}
        personTypeSelected={personTypeSelected}
        setEnterprisePath={setEnterprisePath}
        setOpenACM={setOpenACM}
        isLogin={true}
      />
      <DialogSubscribe
        open={openSubscribe}
        setOpen={setOpenSubscribe}
        setOpenPrivacy={setOpenPrivacy}
        setOpenACM={setOpenACM}
        isLogin={true}
      />
      <BasicDialog
        open={openResetPass}
        setOpen={setOpenResetPass}
        title={t("expiredPassTitle")}
        text={t("expiredPassText")}
        action={() => goToResetPass(true)}
        closeAction={() => goToResetPass(false)}
        justInfo={true}
      />
      <Dialog2FASetPhoneLogin open={open2FA} setOpen={setOpen2FA} auxiliarInfo={auxiliarInfo} finishOperation={finishOperation} />
      <BasicDialog
        open={openACM}
        setOpen={setOpenACM}
        title={t("generalACMTitle")}
        text={getSessionItem(sessionValues.isNoToken) == "true" ? t("generalACMTextGo") : t("generalACMTextNoGo")}
        action={() => goToLoginOauth(finishOperation, t)}
        closeAction={() => goToLoginOauth(finishOperation, t)}
        justInfo={true}
      />
      <Grid container className={classes.loginTitle}>
        <Grid item xs={6} className={classes.logInSubtitle} style={{ alignItems: "left" }}>
          <Typography variant="h6" style={{ fontWeight: "900", color: "#333333" }}>
            {t("logInTitleSelectType")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12} style={{ margin: "1em" }}>
          <SelectorSwitch textA={t("UserEmail")} textB={t("registerPagePhone")} selectedA={!showPhoneSelector} handleSwitch={handleSwitchLogin} size={Size.medium} />
        </Grid>
        <Grid item xs={12} className={commonClasses.gridIdentity}>
          {showPhoneSelector ? (
            <Grid item sm={4} xs={12} className={commonClasses.gridIdentity}>
              <PrefixPhone handleSelectorChange={handleSelectorChange} isLogin={true} masterCountryList={masterCountryList} />
            </Grid>
          ) : null}
          <Grid
            item
            sm={showPhoneSelector ? 8 : 12}
            xs={12}
            className={commonClasses.gridIdentity}
            style={{ paddingLeft: showPhoneSelector ? "0.5em" : "0em" }}
          >
            <div style={{ position: "relative", display: "flex", flexDirection: "column", width: "100%" }}>
              <TextField
                value={username}
                autoFocus
                onInput={handleIdentityInputChange}
                onBlur={handleBlur}
                variant="filled"
                fullWidth
                id="username"
                label={showPhoneSelector ? t("registerPagePhone") : t("UserEmail")}
                name="username"
                color="secondary"
                error={errorMsgUser || errorLogin}
                className={commonClasses.inputsStyle}
              />
              {errorMsgUser != "" ? (
                <FormHelperText error={true} style={{ position: "absolute", bottom: "-20px" }}>
                  {errorMsgUser}
                </FormHelperText>
              ) : null}
            </div>
          </Grid>

        </Grid>

        <Grid item xs={12} style={errorMsgUser ? { marginTop: "1em" } : null} className={commonClasses.gridIdentity} >
          <TextField
            color="secondary"
            value={password}
            onInput={(e) => setPassword(e.target.value)}
            variant="filled"
            margin="normal"
            fullWidth
            name="password"
            label={t("correosPasswordLabel")}
            type={showPassword ? "text" : "password"}
            id="password"
            error={errorLogin}
            helperText={errorLogin ? errorMsgLogin : ""}
            InputProps={{
              className: classes.loginUnderline,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <img src={ico_pass_on} alt="pass_on" />
                    ) : (
                      <img src={ico_pass_off} alt="pass_off" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            className={commonClasses.inputsStyle}
          />
        </Grid>
        {/*CheckBox RememberMe*/}
        <Grid item xs={12} className={commonClasses.gridIdentity}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={handleChangeRememberMe}
                  name="checkedA"
                />
              }
              label={t("rememberme")}
              classes={{ label: classes.loginRememberMeLabel }}
            />
          </FormGroup>
          <button
            type="button"
            className={commonClasses.button_link}
            onClick={() => goToResetPass(true)}
            style={{ marginLeft: "auto" }}
          >
            {t("forgotPasswordButton")}
          </button>
        </Grid>
      </Grid>
      {
        process?.env?.REACT_APP_ENVELOP_ACTIVE_CAPTCHA == "true" ? (
          <Grid style={{ marginTop: "1.5rem" }}>
            <Grid style={{ display: showReCaptcha ? 'inline-block' : 'none' }}>
              <ReCAPTCHA
                ref={(r) => setReCaptchaRef(r)}
                sitekey={process?.env?.REACT_APP_RECAPTCHA_KEY}
                onChange={handleOnChangeReCaptcha}
                size={getScreenWidth() > 440 ? "normal" : "compact"} />

              {checkIsNullUndefined(reCaptchaError) ? null : (
                <p style={{ color: "#f32735", fontSize: "80% !important", textAlign: "start" }}>{reCaptchaError}</p>
              )}
            </Grid>
          </Grid>
        ) : null
      }

      <Grid container item xs={12} style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
        <Grid item xs={12}>
          <CorreosButton
            type="submit"
            className={commonClasses.rightButton}
            variant="contained"
            color="primary"
            disabled={disableLoginButton}
            circularProgress={progressBar}
            size="large"
          >
            {t("logInButton")}
          </CorreosButton>
        </Grid>
      </Grid>
    </form >
  );
};
