import PersonIcon from '@material-ui/icons/Person';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { FaBuilding } from "react-icons/fa";

export const EnumAllowedSSOUserPages = {
    UserData: "UserData",
    UserVerifications: "UserVerifications",
    UserPrivacy: "UserPrivacy",
    EnterpriseCreate: "EnterpriseCreate",
    UserServices: "UserServices",
    UserCancelAccount: "UserCancelAccount",
    UnifyAccounts: "UnifyAccounts",
};

export const EnumAllowedSSOEnterprisePages = {
    UserData: "UserData",
    UserVerifications: "UserVerifications",
    UserPrivacy: "UserPrivacy",
    EnterpriseCreate: "EnterpriseCreate",
    UserServices: "UserServices",
    UserCancelAccount: "UserCancelAccount",
    UnifyAccounts: "UnifyAccounts",
    EnterpriseData: "EnterpriseData",
    EnterpriseVerifications: "EnterpriseVerifications",
    EnterprisePrivacy: "EnterprisePrivacy",
    EnterpriseTeam: "EnterpriseTeam",
    EnterpriseServices: "EnterpriseServices",
    EnterpriseDelete: "EnterpriseDelete",
};

export const EnumAllowedRedirectUserPages = {
    UserData: "UserData",
    UserVerifications: "UserVerifications",
    UserCancelAccount: "UserCancelAccount",
    EnterpriseCreate: "EnterpriseCreate",
    UnifyAccounts: "UnifyAccounts",
};

export const EnumAllowedRedirectEnterprisePages = {
    UserData: "UserData",
    UserVerifications: "UserVerifications",
    UserCancelAccount: "UserCancelAccount",
    EnterpriseCreate: "EnterpriseCreate",
    UnifyAccounts: "UnifyAccounts",
    EnterpriseData: "EnterpriseData",
    EnterpriseVerifications: "EnterpriseVerifications",
    EnterpriseDelete: "EnterpriseDelete",
};

export const EnumEnterprisePages = [
    "EnterpriseData",
    "EnterpriseVerifications",
    "EnterprisePrivacy",
    "EnterpriseTeam",
    "EnterpriseServices",
    "EnterpriseDelete",
];

export const EnumFirstSelectOption = "NOOPTSEL";

export const Size = {
    large: "large",
    medium: "medium",
    small: "small"
};

export const EnumPersonType = {
    User: "User",
    Enterprise: "Enterprise"
};

export const fetchMethod = {
    POST: "POST",
    PUT: "PUT",
    GET: "GET",
    DELETE: "DELETE",
};

export const sessionValues = {
    idToken: "valIdT",
    refreshToken: "refT",
    time: "time",
    appOid: "appV",
    appName: "appN",
    appCode: "appC",
    logged: "logged",
    imageLogin: "imaLog",
    imageRegistry: "imaReg",
    imagePassword: "imaPass",
    allowSignup: "allSign",
    InvitationAccept: "InvAcc",
    AcceptChangeEnterpriseRole: "AccChaEntRol",
    CancelOrRejectChangeRole: "CanOrRejChaRol",
    idInvitation: "idInv",
    originUrl: "oriUrl",
    entranceUrl: "entUrl",
    redirectURI: "redURI",
    replaceMode: "repMod",
    isUserTitular: "isUsTit",
    appPersonType: "appPerTyp",
    attributeInfo: "attInf",
    attributes: "att",
    fieldType: "fieTyp",
    userConfiguration: "userConf",
    enterpriseConfiguration: "entConf",
    offersAndProms: "offAndPro",
    cidPrivacyAndTyc: "cidPriAndTyc",
    pendingData: "penDat",
    state: "state",
    isNoToken: "isNoTok",
};

export const secureValues = {
    user: "user",
    userOid: "userV",
    token: "valT",
    enterpriseList: "entList",
    copyUser: "userC"
};

export const generalWarning = {
    pass: "pass",
    registry: "registry",
    verify: "verify",
};

const generalSteps = {
    createUserCid: "createUserCid",
    subscriptionToApp: "subscriptionToApp",
    userIdentify: "userIdentify",
    requiredData: "requiredData",
};

export const generalPaths = {
    Registry: [generalSteps.createUserCid, generalSteps.subscriptionToApp],
    PendingData: [generalSteps.userIdentify, generalSteps.requiredData],
};

export const iconsList = {
    User: {
        1: <PersonOutlineIcon />,
        2: <PersonIcon />,
    },
    Enterprise: {
        1: <PersonOutlineIcon />,
        2: <FaBuilding />,
    },
};
