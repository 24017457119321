import React, { useEffect, useState } from "react";
import {
  TextField,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
  FormHelperText,
  CardMedia,
} from "@material-ui/core";
import { CommonStyles } from "../../commons/CommonStyles";
import { StylesResetPassword } from "./ResetPasswordStyles";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { PrefixPhone } from "../PrefixPhone/PrefixPhone";
import {
  PostUtilitiesCorreosIdVerifyRecaptcha,
} from "../../services/UtilitiesCallsService";
import ico_pass_on from "../../assets/images/ico_pass_on.svg";
import ico_pass_off from "../../assets/images/ico_pass_off.svg";
import { getEntranceUrl, getSessionItem } from "../../services/Commons";
import { checkIsNullUndefined, getErrorMessage, getScreenWidth, validatePassword, validatePhone } from "../../commons/Utils";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { EnumCountries } from "../../commons/EnumCountries";
import history from "../../services/history";
import { EnumPages } from "../../commons/EnumPages";
import { SelectorSwitch } from "../CommonComponents/SelectorSwitch";
import subscriptionOkImage from "../../assets/images/subok.svg";
import { DialogStyles } from "../CommonComponents/Dialogs/DialogStyles";
import { Size } from "../../commons/EnumsGeneral";
import { PostApplicationAuxUserSend, PutApplicationAuxResetPassword } from "../../services/ApplicationAuxCallsService";

export function ResetPassword(props) {
  const commonClasses = CommonStyles();
  const classes = StylesResetPassword();
  const dialogClasses = DialogStyles();
  const { t, i18n } = useTranslation();
  const validator = require("react-email-validator");

  const { nextStep, previousStep, step, masterCountryList, setOpen, setIsEmail } = props;
  const [emptyError, setEmptyError] = useState(false);
  //user con prefijo, de ser necesario
  const [user, setUser] = useState("");
  const [userError, setUserError] = useState(false);
  const [userErrorMsg, setUserErrorMsg] = useState("");
  //user sin prefijo
  const [userInput, setUserInput] = useState("");
  const [showPrefix, setShowPrefix] = useState(false);
  const [sending, setSending] = useState(false);
  const [changing, setChanging] = useState(false);
  const [country, setCountry] = useState({
    code: EnumCountries.ESP,
    id: 67,
    phonePrefix: 34,
  });
  const [otp, setOtpCode] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [pass, setPass] = useState("");
  const [rPass, setRPass] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [showRPass, setShowRPass] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorCheckPassword, setErrorCheckPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorCheckMsg, setErrorCheckMsg] = useState("");
  const [reCaptchaToken, setReCaptchaToken] = useState(null);
  const [reCaptchaError, setReCaptchaError] = useState("");
  const [reCaptchaRef, setReCaptchaRef] = useState(null);

  useEffect(() => {
    let auxUser = checkIsNullUndefined(getSessionItem("userId")) ? "" : getSessionItem("userId");
    setUserInput(auxUser);
  }, []);

  useEffect(() => {
    handleUserChange(userInput, false);
  }, [userInput, country]);

  useEffect(() => {
    handlePasswordChange(false, true);
  }, [pass]);

  useEffect(() => {
    handlePasswordChange(false, false);
  }, [rPass]);

  useEffect(() => {
    if (step == 2) {
      setTimeout(goBack, 5000);
    };
  }, [step]);

  const inicialize = () => {
    setEmptyError(false);
    setOtpError(false);
    setErrorPassword(false);
    setErrorCheckPassword(false);
    setShowPass(false);
    setShowRPass(false);
    setSending(false);
    setOtpCode("");
    setPass("");
    setRPass("");
    setErrorMsg("");
    setErrorCheckMsg("");
  };

  const goNext = () => {
    inicialize();
    nextStep();
  };

  const handleNextStep = () => {
    if (step == 0) {
      handleSendOtp();
    } else if (step == 1) {
      handleResetPassword()
    } else if (step == 2) {
      goBack();
    };
  };

  const handleSendOtp = () => {
    setSending(true);
    let auxUser = showPrefix ? country.phonePrefix + "-" + userInput : userInput;
    setUser(auxUser);
    PostApplicationAuxUserSend(auxUser).then((response) => {
      if (response && !response.Status) {
        if (step == 0) {
          goNext();
        };
      };
      setSending(false);
    });
  };

  const handleInputPassChange = (event) => {
    setPass(event.target.value);
  };

  const handleInputRPassChange = (event) => {
    setRPass(event.target.value);
  };

  const handleResetPassword = () => {
    setChanging(true);
    PutApplicationAuxResetPassword(user, pass, rPass, otp).then((response) => {
      if (response && !response.Status) {
        setChanging(false);
        goNext();
      } else {
        setOpen(true);
        setChanging(false);
      }
    });
  };

  const handleSelectorChange = (auxCountry) => {
    setCountry(auxCountry);
  };

  const handleSwitchLogin = (emailSelected) => {
    setIsEmail(emailSelected);
    setShowPrefix(!emailSelected);
    setErrorMsg("");
    setUserInput("");
  }

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleOtp = (event) => {
    setOtpCode(event.target.value);
    setOtpError(false);
  };

  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };

  const handleClickShowRPassword = () => {
    setShowRPass(!showRPass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const goBack = () => {
    setErrorMsg("");
    if (step === 0 || step === 2) {
      inicialize();
      history.push(getEntranceUrl(EnumPages.Login));
    } else {
      inicialize();
      setReCaptchaToken(null);
      previousStep();
    }
  };

  const handleOnChangeReCaptcha = (value) => {
    let auxCaptchaTOken = null;
    let auxCatchaError = "";
    if (!checkIsNullUndefined(value)) {
      PostUtilitiesCorreosIdVerifyRecaptcha(value).then((response) => {
        if (response && !response.Status) {
          auxCaptchaTOken = value;
        } else {
          reCaptchaRef.reset();
          auxCatchaError = getErrorMessage(response, t);
        }
        setReCaptchaToken(auxCaptchaTOken);
        setReCaptchaError(auxCatchaError);
      });
    }
  };

  const generateButtons = () => {
    if (step == 2) {
      return (
        <Grid container item xs={12} className={commonClasses.mainButtonContainer}>
          <Grid item xs={12} className={classes.textDivLeft}>
            <Typography variant="h6">
              <span>{t("resetPasswordRedirection")}</span>
              <a
                className={commonClasses.linkDecoration}
                href={getEntranceUrl(EnumPages.Login)}
                // target="_blank"
                rel={"noopener noreferrer"}
              >
                {t("here")}
              </a>
            </Typography>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container item xs={12} className={commonClasses.mainButtonContainer}>
          <Grid item xs={6}>
            <CorreosButton
              className={commonClasses.leftButton}
              onClick={goBack}
              size="large"
            >
              {step === 0
                ? t("cancel")
                : t("backButton")}
            </CorreosButton>
          </Grid>
          <Grid item xs={6}>
            <CorreosButton
              variant="contained"
              color="primary"
              className={commonClasses.rightButton}
              onClick={handleNextStep}
              disabled={handleDisabledButton()}
              size="large"
              circularProgress={step == 0 ? sending : changing}
            >
              {step === 0 ? t("nextButton") : t("accept")}
            </CorreosButton>
          </Grid>
        </Grid>
      );
    }
  };

  const handleUserChange = (value, isBlur) => {
    let auxFunction = showPrefix ? checkIsValidPhone : checkIsValidEmail;
    let auxErrorMsg = "";
    let auxError = false;
    if (checkIsNullUndefined(value)) {
      auxError = true;
    } else if (!auxFunction(value)) {
      auxError = true;
      if (isBlur) {
        auxErrorMsg = t("loginFormatError")
      }
    }
    setUserError(auxError);
    setUserErrorMsg(auxErrorMsg);
  };

  const checkIsValidPhone = (value) => {
    const phoneRegex = /^\d{9}$/;
    let auxFlag = phoneRegex.test(value);
    if (auxFlag) {
      auxFlag = validatePhone(value, country);
    }
    return auxFlag;
  };

  const checkIsValidEmail = (value) => {
    return validator.validate(value);
  };

  const handleDisabledButton = () => {
    let auxFlag = false;
    if (step == 0) {
      auxFlag = checkIsNullUndefined(userInput) || userError || (process?.env?.REACT_APP_ENVELOP_ACTIVE_CAPTCHA == "true" && (checkIsNullUndefined(reCaptchaToken) || reCaptchaError));
    } else if (step == 1) {
      auxFlag = checkIsNullUndefined(otp) || otpError || checkIsNullUndefined(pass) || checkIsNullUndefined(rPass) || errorPassword || errorCheckPassword;
    }
    return auxFlag;
  }

  const handlePasswordChange = (isBlur, isPass) => {
    let auxPassError = false;
    let auxCheckError = false;
    let auxErrorMsg = "";
    let auxErrorCheckMsg = "";
    if (checkIsNullUndefined(pass)) {
      auxPassError = true;
      if (isBlur) {
        auxErrorMsg = t("emptyError");
      }
    } else if (!validatePassword(pass)) {
      auxPassError = true;
      if (isBlur) {
        auxErrorMsg = t("resetPasswordFormatError");
      }
    }
    if (pass !== rPass) {
      auxCheckError = true;
      if (!auxPassError && isBlur && !checkIsNullUndefined(rPass)) {
        auxErrorCheckMsg = t("resetPasswordCheckError");
      }
    }
    setErrorPassword(auxPassError);
    setErrorCheckPassword(auxCheckError);
    setErrorMsg(auxErrorMsg);
    setErrorCheckMsg(auxErrorCheckMsg);
  };

  return (
    <Grid className={commonClasses.mainDiv}>
      <Grid container alignItems="center">
        {step == 0 ? (
          <Grid container item xs={12}>
            <Grid item xs={12} style={{ margin: "1em" }}>
              <SelectorSwitch textA={t("UserEmail")} textB={t("registerPagePhone")} selectedA={!showPrefix} handleSwitch={handleSwitchLogin} size={Size.medium} />
            </Grid>
            <Grid item xs={12} className={commonClasses.textDiv} style={{ marginTop: 0 }}>
              <Grid container item xs={12} spacing={1} >
                {showPrefix ? (
                  <Grid item sm={4} xs={12} className={commonClasses.gridIdentity}>
                    <PrefixPhone handleSelectorChange={handleSelectorChange} masterCountryList={masterCountryList} country={country} />
                  </Grid>
                ) : (
                  ""
                )}
                <Grid
                  item
                  sm={showPrefix ? 8 : 12}
                  xs={12}
                  className={commonClasses.gridIdentity}
                >
                  <div style={{ position: "relative", display: "flex", flexDirection: "column", width: "100%" }}>
                    <TextField
                      value={userInput}
                      onInput={handleInputChange}
                      onBlur={(event) => handleUserChange(event.target.value, true)}
                      label={showPrefix ? t("registerPagePhone") : t("UserEmail")}
                      color="secondary"
                      variant="filled"
                      fullWidth
                      error={userError && !checkIsNullUndefined(userErrorMsg)}
                      autoFocus
                      className={commonClasses.inputsStyle}
                    />
                    {userErrorMsg != "" ? (
                      <FormHelperText error={true} style={{ position: "absolute", bottom: "-20px" }}>
                        {userErrorMsg}
                      </FormHelperText>

                    ) : null}
                  </div>
                </Grid>
              </Grid>
              {process?.env?.REACT_APP_ENVELOP_ACTIVE_CAPTCHA == "true" ? (
                <Grid style={{ display: 'inline-block', marginTop: '2.5em' }}>
                  <ReCAPTCHA
                    ref={(r) => setReCaptchaRef(r)}
                    sitekey={process?.env?.REACT_APP_RECAPTCHA_KEY}
                    onChange={handleOnChangeReCaptcha}
                    size={getScreenWidth() > 440 ? "normal" : "compact"} />

                  {checkIsNullUndefined(reCaptchaError) ? null : (
                    <p style={{ color: "#f32735", fontSize: "80% !important", textAlign: "start" }}>{reCaptchaError}</p>
                  )}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        ) : step == 1 ? (
          <Grid container item xs={12} spacing={1} style={{ marginTop: "1em" }}>
            <Grid item xs={9} >
              <TextField
                value={userInput}
                label={showPrefix ? t("registerPagePhone") : t("UserEmail")}
                color="secondary"
                variant="filled"
                fullWidth
                type="text"
                disabled={true}
                className={commonClasses.inputsStyle}
              />
            </Grid>
            <Grid item xs={3} className={classes.gridIdentity}>
              <CorreosButton
                variant="outlined"
                color="secondary"
                className={commonClasses.dialogButtonCenter}
                onClick={handleSendOtp}
                // size="small"
                style={{ marginTop: "auto" }}
                circularProgress={sending}
              >
                {t("reSend")}
              </CorreosButton>
            </Grid>
            <Grid item xs={12} >
              <TextField
                value={otp}
                onInput={handleOtp}
                // className={classes.OtpTextField}
                className={commonClasses.inputsStyle}
                label={t("lOtp")}
                color="secondary"
                variant="filled"
                fullWidth
                // type="number"
                error={emptyError || otpError}
                helperText={
                  emptyError ? t("otpEmptyError") : otpError ? t("otpRpError") : ""
                }
                autoFocus
              />
            </Grid>
            {!checkIsNullUndefined(otp) ? (
              <Grid item container xs={12}>
                <Grid item xs={12} className={classes.textDivLeft}>
                  <Typography variant="h6">
                    {t("lChangePass")}
                  </Typography>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      color="secondary"
                      value={pass}
                      onInput={handleInputPassChange}
                      onBlur={(event) => handlePasswordChange(true, true)}
                      variant="filled"
                      fullWidth
                      name="password"
                      label={t("lPass")}
                      type={showPass ? "text" : "password"}
                      id="password"
                      error={errorPassword && !checkIsNullUndefined(errorMsg)}
                      helperText={errorMsg}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPass ? (
                                <img src={ico_pass_on} alt="pass_on" />
                              ) : (
                                <img src={ico_pass_off} alt="pass_off" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      className={commonClasses.inputsStyle}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      color="secondary"
                      value={rPass}
                      onInput={handleInputRPassChange}
                      onBlur={(event) => handlePasswordChange(true, false)}
                      variant="filled"
                      fullWidth
                      name="rPassword"
                      label={t("lRPass")}
                      type={showRPass ? "text" : "password"}
                      id="password"
                      error={errorCheckPassword && !checkIsNullUndefined(errorCheckMsg)}
                      helperText={errorCheckMsg}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowRPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showRPass ? (
                                <img src={ico_pass_on} alt="pass_on" />
                              ) : (
                                <img src={ico_pass_off} alt="pass_off" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      className={commonClasses.inputsStyle}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

          </Grid>
        ) : step == 2 ? (
          <Grid container item xs={12}>
            <Grid item xs={12} className={commonClasses.textDiv}>
              <CardMedia className={dialogClasses.mediaSubOk}>
                <img
                  className={dialogClasses.imgCardMediaSubOk}
                  src={subscriptionOkImage}
                  alt="Logo"
                />
              </CardMedia>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      {generateButtons()}
    </Grid>
  );
}
